import React from 'react'

import theme from '../theme/theme'

class Reset extends React.Component {
	componentDidMount() {
		const queryURL = new URLSearchParams(window.location.search)
		window.location.replace(theme.redirects.reset + '/?email=' + queryURL.get('email')
			+ '&code=' + queryURL.get('code'))
	}

	render () {
		return true
	}
}

export default Reset
